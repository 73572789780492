import { gql, useQuery } from '@apollo/client';
import { Box, BoxProps, Button, Text, useDisclosure } from '@chakra-ui/react';
import { convertFromRaw } from 'draft-js';
import * as React from 'react';

import { PrivacyPolicyModal } from '@/components/PrivacyPolicyModal';
import { useCurrent } from '@/hooks/useCurrent';

export const PrivacyPolicyBanner: React.FC<BoxProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const current = useCurrent();

  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (current.tenant?.obnoxiousPrivacyPolicy === true) {
        onOpen();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [current.tenant?.obnoxiousPrivacyPolicy, onOpen]);

  if (!current.tenant) {
    return null;
  }

  if (!current.user) {
    return null;
  }

  if (!!current.user.acceptedPrivacyPolicy) {
    return null;
  }

  if (!current.tenant.privacyPolicy) {
    return null;
  }

  if (!current.tenant.obnoxiousPrivacyPolicy) {
    return null;
  }

  if (convertFromRaw(current.tenant.privacyPolicy).getPlainText() === '') {
    return null;
  }

  if (!current.user.onboardingFlowState.isSkippedOrCompleted) {
    return null;
  }

  return (
    <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />
  );
};
