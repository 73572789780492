import { gql, useQuery } from '@apollo/client';
import { Box, BoxProps, Button, Text, useDisclosure } from '@chakra-ui/react';
import { convertFromRaw } from 'draft-js';
import * as React from 'react';

import { TermsAndConditionsModal } from '@/components/TermsAndConditionsModal';
import { useCurrent } from '@/hooks/useCurrent';

export const TermsAndConditionsBanner: React.FC<BoxProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const current = useCurrent();

  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (current.tenant?.obnoxiousTermsAndConditions === true) {
        onOpen();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [current.tenant?.obnoxiousTermsAndConditions, onOpen]);

  if (!current.tenant) {
    return null;
  }

  if (!current.user) {
    return null;
  }

  if (!!current.user.acceptedTermsAndConditions) {
    return null;
  }

  if (!current.tenant.termsAndConditions) {
    return null;
  }

  if (!current.tenant.obnoxiousTermsAndConditions) {
    return null;
  }

  if (convertFromRaw(current.tenant.termsAndConditions).getPlainText() === '') {
    return null;
  }

  if (!current.user.onboardingFlowState.isSkippedOrCompleted) {
    return null;
  }

  return (
    <TermsAndConditionsModal isOpen={isOpen} onClose={onClose} />
  );
};
