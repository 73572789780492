import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { BookmarkOnMobileDeviceModalProvider } from '@/components/BookmarkOnMobileDeviceModal';
import { CodeOfConductBanner } from '@/components/CodeOfConductBanner';
import { NewClientAvailable } from '@/components/NewClientAvailable';
import { useElementHeight } from '@/hooks/useElementHeight';
import { ImpersonationAlert } from '@/modules/accounts/impersonation';

import { Header } from './components/Header';
import { PrivacyPolicyBanner } from '@/components/PrivacyPolicyBanner';
import { TermsAndConditionsBanner } from '@/components/TermsAndConditionsBanner';

const BlueprintContext = React.createContext<{
  portal: React.RefObject<HTMLDivElement>;
  headerHeight: number;
  forceMatchHack: string | null;
  setForceMatchHack: React.Dispatch<React.SetStateAction<string | null>>;
}>({
  portal: { current: null },
  headerHeight: 0,
  forceMatchHack: null,
  setForceMatchHack: () => null,
});

export const useBlueprint = () => React.useContext(BlueprintContext);

export const BlueprintPortal: React.FC = (props) => {
  const { portal } = React.useContext(BlueprintContext);

  return portal.current
    ? ReactDOM.createPortal(props.children, portal.current)
    : null;
};

export const Blueprint: React.FC<
  BoxProps & { isHeaderContained?: boolean }
> = ({ isHeaderContained, ...props }) => {
  const portal = React.useRef<HTMLDivElement>(null);

  const header = useElementHeight();

  const [forceMatchHack, setForceMatchHack] = React.useState<string | null>(
    null
  );

  // TODO: this useEffect is technically not necessary now because Next.js
  // re-renders this component on every route change. once we switch to next
  // version 13 and use the app folder + layouts then we will need to add this
  // back in to avoid the forceMatch sticking around after you navigate

  // React.useEffect(() => {
  //   const resetForceMatchHack = () => setForceMatchHack('');
  //   // const resetForceMatchHack = (url: string) => setForceMatchHack(value => url.startsWith(value) ? value : '');
  //   router.events.on('routeChangeComplete', resetForceMatchHack);
  //   return () => router.events.off('routeChangeComplete', resetForceMatchHack);
  // });

  // TODO: once we switch to next version 13 and use the app folder + layouts
  // we need to check if BookmarkOnMobileDeviceModalProvider still works right
  // in regards to loading before checking the route restrictions

  return (
    <BlueprintContext.Provider
      value={{
        portal,
        headerHeight: header.height,
        forceMatchHack,
        setForceMatchHack,
      }}
    >
      <BookmarkOnMobileDeviceModalProvider>
        <Box
          ref={header.ref}
          position="sticky"
          top="0"
          zIndex="docked"
          {...props}
        >
          <NewClientAvailable zIndex="6" position="relative" />
          <ImpersonationAlert zIndex="5" position="relative" />
          <Header
            zIndex="4"
            position="relative"
            isContained={isHeaderContained}
          />
          <TermsAndConditionsBanner zIndex="2" position="relative" />
          <PrivacyPolicyBanner zIndex="2" position="relative" />
          <CodeOfConductBanner zIndex="3" position="relative" />
          <Box ref={portal} zIndex="1" position="relative" />
        </Box>

        {props.children}
      </BookmarkOnMobileDeviceModalProvider>
    </BlueprintContext.Provider>
  );
};
