import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';

import { Redraft } from '@/components/Redraft';
import { useCurrent } from '@/hooks/useCurrent';
import { Emoji } from '@/imports/ui/chakra/components/Emoji';
import { Prose } from '@/imports/ui/chakra/components/Prose';
import { useImpersonation } from '@/modules/accounts/impersonation';

import {
  TermsAndConditionsModal_AcceptTermsAndConditions,
  TermsAndConditionsModal_AcceptTermsAndConditionsVariables,
} from './graphql-types/TermsAndConditionsModal_AcceptTermsAndConditions';

const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation TermsAndConditionsModal_AcceptTermsAndConditions(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptTermsAndConditions(input: $input) {
      user {
        _id
        acceptedTermsAndConditions
      }
    }
  }
`;

export const TermsAndConditionsModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const current = useCurrent();
  const { impersonationActive } = useImpersonation();
  const [checked, setChecked] = React.useState(
    current.user?.acceptedTermsAndConditions ? true : false
  );

  const [acceptTermsAndConditions, acceptTermsAndConditionsResult] = useMutation<
  TermsAndConditionsModal_AcceptTermsAndConditions,
  TermsAndConditionsModal_AcceptTermsAndConditionsVariables
  >(ACCEPT_TERMS_AND_CONDITIONS);

  const showClose =
    !current.tenant?.obnoxiousTermsAndConditions ||
    !!current.user?.acceptedTermsAndConditions;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={showClose}
      /** Backdoor for admins to impersonate users without accepting the tnp for them */
      closeOnEsc={impersonationActive || showClose}
      isCentered
      size={['full', 'full', 'xl']}
    >
      <ModalOverlay />
      <ModalContent>
        {showClose && <ModalCloseButton color="black" />}
        <ModalHeader textAlign="center" pt="10" pb="6">
          <VStack spacing="2">
            <Emoji symbol="📃" fontSize="4xl" />
            <Heading size="md">
              {!current.user?.acceptedTermsAndConditions && (
                <>{`Please Read and Accept the `}</>
              )}
              {`${current.tenant?.displayName} Terms And Conditions`}
            </Heading>
          </VStack>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" pb="6">
          <Box
            bg="gray.100"
            p="4"
            h="xs"
            overflow="auto"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="xl"
          >
            {current.tenant?.termsAndConditions && (
              <Prose>
                <Redraft rawDraftContentState={current.tenant?.termsAndConditions} />
              </Prose>
            )}
          </Box>
        </ModalBody>
        <ModalFooter pt="2" pb="6">
          {!current.user?.acceptedTermsAndConditions ? (
            <Flex w="full" justify="space-between">
              <Checkbox
                isDisabled={!!current.user?.acceptedTermsAndConditions}
                isChecked={checked}
                onChange={() => setChecked((oldValue) => !oldValue)}
                mr="2"
              >
                {`I've read and accepted the terms and conditions`}
              </Checkbox>
              <Button
                isDisabled={!checked || !!current.user?.acceptedTermsAndConditions}
                isLoading={acceptTermsAndConditionsResult.loading}
                onClick={async () => {
                  if (checked) {
                    await acceptTermsAndConditions({
                      variables: { input: { isPartOfOnboardingFlow: false } },
                    });
                    props.onClose();
                  }
                }}
              >
                Accept
              </Button>
            </Flex>
          ) : (
            <Button onClick={props.onClose}>Okay</Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
